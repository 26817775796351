<template>
  <div>
    <el-radio-group v-model="type">
      <el-radio label="0" border>全部公开</el-radio>
      <el-radio label="1" border>部分公开</el-radio>
      <el-radio label="2" border>私有</el-radio>
    </el-radio-group>

    <div v-if="type === '1'" style="margin-top: 24px">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" style="margin-bottom: 24px" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkedUnitIdList"  @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.id"  v-for="(item, index) in shareList" :key="index">{{ item.name }}</el-checkbox>
      </el-checkbox-group>
    </div>


    <div style="display: flex;justify-content: flex-end;width: 100%;margin-top: 24px;padding-top: 12px;border-top: 1px #cccccc solid">
      <el-button type="primary" @click="onSubmit">完成</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "question-bank-auth",
  data() {
    return {
      type: "",
      checkedUnitIdList: [],
      qbId: '',
      isIndeterminate: true,
      checkAll: false
    }
  },
  props: {
    shareList: {
      type: Array,
      default: () => {
        return []
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    info: {
      handler(val) {
        console.log(val, "val")
        this.qbId = val.id
        this.checkedUnitIdList = val.shared_unit
        if (!val.shared) {
          this.type = "2"
        } else if (val.shared_unit.length > 0) {
          this.type = "1"
        } else {
          this.type = "0"
        }

        this.changeCheckboxStatus()
      },
      deep: true,
      immediate: true
    },
    shareList: {
      handler() {
        this.changeCheckboxStatus()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async onSubmit() {
      let shared = false
      let unitIdList = []
      if (this.type === '0') {
        shared = true
      } else if(this.type === '1') {
        unitIdList = this.checkedUnitIdList
        shared = true
      }

      await this.$api.shareQuestionBank({
        id: this.qbId,
        shared: shared,
        unit_id_list : unitIdList
      })

      this.$emit("finish")
    },

    changeCheckboxStatus() {
      this.checkAll = this.checkedUnitIdList.length === this.shareList.length;
      this.isIndeterminate = this.checkedUnitIdList.length > 0 && this.checkedUnitIdList.length < this.shareList.length;
    },

    handleCheckAllChange(val) {
      this.checkedUnitIdList = [];
      if (val) {
        for (let item of this.shareList) {
          this.checkedUnitIdList.push(item.id)
        }
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.changeCheckboxStatus()
    }
  }
}
</script>

<style scoped>

</style>