<template>
  <div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(config, index) in configList" :key="index"
                   :label="config.type_name +'(' + config.record_num+')'" :name="config.type">
        <el-table
            :border="true"
            :data="getData(config)"
            max-height="600">
          <template v-for="(c, cIndex) in config.column_define">
            <el-table-column
                :key="cIndex"
                :width="getColumnWidth(c.id)"
                :prop="c.id"
                :label="c.label"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
          </template>


        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div style="display: flex;justify-content: flex-end;width: 100%;margin-top: 24px;">
      <div>
        <span style="padding-right: 12px">
          共 <span style="color: #0099ff"> {{ getTotalRecordNum() }} </span>题
        </span>
        <el-button type="primary" @click="onImportClick">导入</el-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "question-import-preview",
  data() {
    return {
      activeName: ""
    }
  },
  props: {
    configList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  watch: {
    configList: {
      handler(val) {
        if (val && val.length > 0) {
          this.activeName = val[0].type
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClick() {

    },
    getData(config) {
      if (!config) {
        return []
      }

      let arr = config.column_values

      return arr
    },
    getSummaries() {
      const sums = [];
      sums[11] = 1233313;
      return sums
    },

    getColumnWidth(columnId) {
      let width = ""
      switch (columnId) {
        case "business_type":
          width = 300
          break
        case "question":
          width = 300
          break
      }

      return width
    },

    getTotalRecordNum() {
      let totalNum = 0
      for (let config of this.configList) {
        totalNum += config.record_num
      }
      return totalNum
    },

    onImportClick() {
      this.$emit("finish")
    }
  }
}
</script>

<style scoped lang="stylus">
</style>