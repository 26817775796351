<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col :span="18">
            <el-button type="primary" size="medium"
                       v-if="userPermissions.indexOf('question_bank_create') !== -1 "
                       @click="addButton(0)">添加
            </el-button>
          </el-col>
          <el-col :span="5" :offset="9">
            <a :href="temUrl" target="_blank" style="color: #1890ff">导入模板下载</a>
          </el-col>
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="题库名称">
              <el-input placeholder="请输入题库名称" clearable v-model="searchForm.name"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              v-if="buttonType==='icon'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                     <el-tooltip effect="dark" content="题库授权" placement="top-start"
                                 v-if="userPermissions.indexOf('question_bank_share') !== -1">
                  <el-button size="mini" icon="el-icon-share" @click="authButton(scope.row.id)"></el-button>
                </el-tooltip>
                     <el-tooltip effect="dark" content="导入" placement="top-start"
                                 v-if="userPermissions.indexOf('question_bank_import') !== -1 && (userInfo.is_manager === 'T' || (userInfo.is_manager === 'F' && userInfo.unit_id === scope.row.unit_id))">
                  <el-button size="mini" icon="el-icon-upload" @click="importButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('question_bank_edit') !== -1  && (userInfo.is_manager === 'T' || (userInfo.is_manager === 'F' && userInfo.unit_id === scope.row.unit_id))">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>

              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType==='text'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                  <el-button size="mini"
                             v-if="userPermissions.indexOf('question_bank_import') !== -1"
                             @click="importButton(scope.row.id)">导入</el-button>

                <el-button size="mini" v-if="userPermissions.indexOf('question_bank_edit') !== -1"
                           @click="editButton(scope.row.id)">编辑</el-button>


              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form
            :model="formData"
            :rules="rules"
            ref="questionBankForm"
            label-position="right"
            label-width="96px"
        >
          <el-form-item label="题库名称" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="题库题型" prop="types">
            <el-checkbox-group v-model="formData.types" size="mini">
              <template v-for="(type, index) in formData.config">
                <div class="question-type" :key="type.id">
                  <el-checkbox
                      :label="type.isEdit ? '' : `${type.question_type_name}(${type.question_type_code})`"
                      style="margin-left: 0"
                  />
                  <div style="display: inline-block" v-show="type.isEdit">
                    <input type="text" clearable v-model="customTypeName" @keyup.enter="changeTypeConfirm(index)"/>
                    <span class="change-type-btn type-cancel-btn" @click="changeTypeCancel(index)">取消</span>
                    <span class="change-type-btn type-confirm-btn" @click="changeTypeConfirm(index)">确定</span>
                  </div>
                  <i v-show="!type.isEdit" class="el-icon-edit" title="自定义题库题型名称" @click="editQuestionType(index)"/>
                  <el-switch
                      style="margin-left: 20px; color: #999"
                      v-if="type.question_type_code === 'multiple_choice' && !type.isEdit"
                      v-model="isAllowSingle"
                      active-text="允许单选"
                      inactive-text="只能多选"
                  />
                </div>
              </template>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="难度" prop="difficulty">
            <el-select
                v-model="formData.difficulty"
                filterable
                clearable
                placeholder="请选择难度"
            >
              <el-option
                  v-for="item in difficultList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio label="0" border>启用</el-radio>
              <el-radio label="-1" border>禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </template>
    </ApeDrawer>

    <ApeDrawer :drawerData="drawerImportData" @drawerClose="importDrawerClose" @drawerConfirm="importDrawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="importDrawerForm" label-width="96px">
          <el-form-item label="题库ID">
            <el-input v-model="importDrawerForm.id" :disabled="true"/>
          </el-form-item>

          <el-form-item label="题库名称">
            <el-input v-model="importDrawerForm.name" :disabled="true" maxlength="10"/>
          </el-form-item>

          <el-form-item label="上传文件">
            <uploader-file :limit="1" @handleUploadSuccess="handleUploadSuccess"
                           @handleUploadRemove="handleUploadRemove"
                           :upload-file-list="uploadFileList"
            ></uploader-file>
          </el-form-item>
        </el-form>
      </template>
    </ApeDrawer>

    <el-dialog :visible.sync="isQuestionBankAuthDialog" :close-on-click-modal="false" width="40%" title="题库授权" :destroy-on-close="true"
               :append-to-body="true">
      <question-bank-auth @finish="onFinish" :share-list="shareList" :info="qbInfo"></question-bank-auth>
    </el-dialog>

    <el-dialog :visible.sync="showPreviewDialog" :close-on-click-modal="false" width="80%" :title="previewTitle" :destroy-on-close="true"
               :append-to-body="true" v-loading.fullscreen.lock="fullscreenLoading">
      <question-import-preview :configList="configList" @finish="onPreviewFinish"></question-import-preview>
    </el-dialog>
  </div>
</template>

<script>

import QuestionImportPreview from "./components/QuestionImportPreview";
const DEL = "del";
const NO_DEL = "no_del";

import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import Sortable from 'sortablejs'
import UploaderFile from '@/components/UploaderFile'
import {mapGetters} from 'vuex'
import QuestionBankAuth from "@/pages/question_bank/components/QuestionBankAuth";

export default {
  components: {
    QuestionImportPreview,
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    UploaderFile,
    // ChangeQuestionBank,
    QuestionBankAuth,
  },
  data() {
    return {
      fullscreenLoading: false,
      configList:[],
      shareList : [],
      qbInfo: {},
      isQuestionBankAuthDialog: false,
      showPreviewDialog: false,
      loadingStatus: true,
      columns: [
        {
          title: '题库名称',
          value: 'name',
        },
        {
          title: '题目数量',
          value: 'question_count'
        },
        {
          title: '难度',
          value: 'difficulty_alias'
        },
        {
          title: '所属单位',
          value: 'unit_name'
        },
        {
          title: '状态',
          value: 'status',
          value_display: "status_alias",
          style_plan: [
            {
              value: "0",
              style: "color:#67C23A;"
            },
            {
              value: "-1",
              style: "color:#F56C6C;"
            }
          ]
        },
        {
          title: '类型',
          value: 'share_status',
          value_display: "share_status_alias",
          style_plan: [
            {
              value: "0", //私有
              style: "color:#333333;"
            },
            {
              value: "1", //部分
              style: "color:#0099ff;"
            },
            {
              value: "2", //全部
              style: "color:#F56C6C;"
            }
          ]
        },
        {
          title: '创建时间',
          value: 'created_at'
        },
      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: null, // 分页的offset,序号列使用
      },
      customTypeName: '',
      isAllowSingle: true,
      // 表单结构
      formData: {
        name: '',
        difficulty: 1,
        status: '0',
        config: [],
        types: ['']
      },
      // 表单验证
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择题库状态', trigger: 'blur'}
        ],
        difficulty: [
          {required: true, message: '请选择题库状态', trigger: 'blur'}
        ],
        types: [
          {required: true, message: '请选择题库题型', trigger: 'blur'}
        ]
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '600px',
      },

      //上传文件抽屉数
      drawerImportData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '600px',
      },
      typeList: [],
      //搜索表单
      searchForm: {},
      //自动填充数据
      restaurants: [],
      //导入题库表单数据
      importDrawerForm: {},

      //文件上传
      uploadFileList: [],

      //题库ID
      curQb: {},

      //难度
      difficultList: [
        {
          title: "容易",
          value: 1,
        },
        {
          title: "困难",
          value: 3
        }
      ],

      temUrl: "",
      userInfo: {},
      previewTitle: ''
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  async mounted() {
    this.userInfo = await this.$api.getPassPortUserInfo();
    if (this.userInfo && this.userInfo.is_manager !== 'T') {
      let pos = this.columns.findIndex(item => item.value === "share_status")
      if (pos) {
        this.columns.splice(pos, 1);
      }
    }
    this.initQuestionBankList();
    let axiosBaseUrl = process.env.VUE_APP_AXIOS_BASE_URL;
    let url = axiosBaseUrl.substring(0, axiosBaseUrl.lastIndexOf('/'));
    this.temUrl = url + '/attachments/moban.xlsx';
    this.$nextTick(() => {
      this.dragSort()
    })
  },
  methods: {
    // 切换页码操作
    switchPaging() {
      this.initQuestionBankList()
    },

    // 响应添加按钮
    addButton() {
      this.drawerData.visible = true;
      this.drawerData.title = '添加题库';
      this.typeList = [];//list
      this.getQuestionBankDefaultConfig()
    },

    // 获取所有题型
    async getQuestionBankDefaultConfig() {
      this.drawerData.loading_text = '玩命加载中……';
      let {list} = await this.$api.getQuestionBankDefaultConfig()
      this.setMultipleStatus(list);
      this.$set(this.formData, 'config', list)
      this.drawerData.loading = false
    },

    // 设置多选题的可选状态
    setMultipleStatus(list) {
      if (Array.isArray(list) && list.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].question_type_code === 'multiple_choice') {
            let p_str = list[i].extra_param && JSON.parse(list[i].extra_param)
            this.isAllowSingle = p_str.allow_single
            return
          }
        }
      }
    },
    // 设置已选中选状态
    setTypes(list) {
      let result = []
      if (Array.isArray(list) && list.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].checked) {
            let name = `${list[i].question_type_name}(${list[i].question_type_code})`
            result.push(name)
          }
        }
      }
      result.push('')
      this.$set(this.formData, 'types', result)
    },

    // 自定义题目类型名称
    editQuestionType(index) {
      let list = this.formData.config;
      if (Array.isArray(list) && list.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].isEdit) {
            this.$set(list[i], 'isEdit', false)
            break
          }
        }
      }
      this.$set(list[index], 'isEdit', true)
    },
    // 设置题库题型名称
    changeTypeConfirm(index) {
      let name = this.customTypeName.trim()
      if (name) {
        let {config, types} = this.formData
        let beforeName = config[index].question_type_name
        let beforeCode = config[index].question_type_code
        let filter_index = types.findIndex(item => item.startsWith(beforeName))
        filter_index === -1 ? types.push(name) : this.$set(types, filter_index, `${name}(${beforeCode})`)
        this.$set(config[index], 'question_type_name', name)
      }
      this.changeTypeCancel(index)
    },
    changeTypeCancel(index) {
      this.customTypeName = '';
      this.$set(this.formData.config[index], 'isEdit', false);
    },

    //响应导入按钮
    async importButton(id) {
      let isManager = this.userInfo.is_manager
      let loginUnitId = this.userInfo.unit_id

      this.drawerImportData.visible = true;
      this.drawerImportData.loading_text = '玩命加载中……';
      this.drawerImportData.visible = true;
      this.drawerImportData.title = '导入文件';
      let {info} = await this.$api.getQuestionBankInfo(id);
      if (isManager !== 'T' && (loginUnitId !== info.unit_id)) {
        this.drawerData.loading = false
        this.$message.error("无权操作");
        return
      }
      this.typeList = [];//list
      this.importDrawerForm = info;
      this.curQb = info;
      this.$nextTick(() => {
        this.drawerImportData.loading = false
      })
    },

    // 响应编辑按钮
    async editButton(id) {
      let isManager = this.userInfo.is_manager
      let loginUnitId = this.userInfo.unit_id

      this.drawerData.loading_text = '玩命加载中……';

      this.drawerData.title = '编辑题库(ID：' + id + ')';
      this.typeList = [];//list
      let {info} = await this.$api.getQuestionBankInfo(id);
      if (isManager !== 'T' && (loginUnitId !== info.unit_id)) {
        this.drawerData.loading = false
        this.$message.error("无权操作");
        return
      }
      this.drawerData.visible = true;
      this.setMultipleStatus(info.config);
      this.formData = info;
      this.setTypes(info.config)
      this.formData.difficulty = Math.ceil(info.difficulty);
      this.drawerData.loading = false
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['questionBankForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    setConfig() {
      let {types, config} = this.formData
      const filter_formData = config.filter(item => {
        if (item.question_type_code === 'multiple_choice') {
          let Obj = {}
          Obj.allow_single = this.isAllowSingle
          item.extra_param = JSON.stringify(Obj)
        }
        let name = `${item.question_type_name}(${item.question_type_code})`
        return types.indexOf(name) !== -1
      })
      this.$set(this.formData, 'config', filter_formData)
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      this.setConfig();
      let id = await this.$api.saveQuestionBank(this.formData);
      if (id) {
        await this.initQuestionBinitQuestionBankListankList(NO_DEL, this.searchForm)
        this.$message.success('保存成功!')
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.resetFormData()
    },

    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteQuestionBank({id});
      if (info) {
        this.$nextTick(() => {
          this.$message.success("题库已禁用");
          this.initQuestionBankList(DEL)
        })
      } else {
        this.$message.error(info)
      }

    },

    // 初始化数据
    resetFormData() {
      // 初始化form表单
      this.formData = {
        name: '',
        status: '0',
        difficulty: 1,
        config: [],
        types: []
      };

      this.$nextTick(() => {
        this.$refs['questionBankForm'].resetFields();
      })
    },

    // 初始化题库列表
    async initQuestionBankList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        if (params[param] === '') {
          continue
        }
        inputData[param] = params[param];
      }
      let {list, pages} = await this.$api.getQuestionBankList(inputData);
      this.questionBankList = [];
      this.$nextTick(() => {
        this.questionBankList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit() {
      // let info = await this.$api.orderCarousel(orderIds)
      // if (info == 'ok') {
      //     this.$nextTick(() => {
      //         this.initCarouselList()
      //     })
      // }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    onSearchClick() {

      this.$refs['apeTable'].resetCurPageSize();
      this.initQuestionBankList(DEL, this.searchForm);
    },

    //获取题库所有名称
    async getAllQuestionBankName() {
      let inputData = {
        page_size: 1000,
        current_page: 1
      };
      let {list, pages} = await this.$api.getQuestionBankList(inputData);

      this.$nextTick(() => {
        if (list.length < 0) {
          return;
        }

        for (let item of list) {
          let itemObjt = {
            value: item.name,
            id: item.id
          };

          this.restaurants.push(itemObjt);
        }

      });
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {}
      this.initQuestionBankList();
    },

    //导入文件抽屉确定
    async importDrawerConfirm() {
      // this.resetFormData();
      this.drawerImportData.visible = true;
      this.drawerImportData.loading = true;

      if (!this.curQb.id) {
        this.$message.error("题库ID不存在");
        this.drawerImportData.loading = false;
        return;
      }

      let fileId = this.importDrawerForm.file_id;
      if (!fileId) {
        this.$message.error("请先上传文件");
        return;
      }

      let info = await this.$api.previewImportQuestion(fileId)
      console.log(info)
      this.configList = info
      this.previewTitle = "导入\"" + this.curQb.name + "\"题库前预览"
      this.showPreviewDialog = true

      this.drawerImportData.loading = false;
    },

    //导入文件抽屉取消
    importDrawerClose() {
      this.resetFormData();
      this.uploadFileList = [];
      this.drawerImportData.visible = false;
      this.drawerImportData.loading = true
    },

    // 文件上传成功回调
    handleUploadSuccess(file, fileList) {
      this.importDrawerForm.file_id = file.id;
    },
    // 文件删除回调
    handleUploadRemove(file, fileList) {
      this.importDrawerForm.file_id = 0;
    },

    // //重置
    // onResetTableSearch() {
    //     this.$refs.searchBox.onResetSearchClick();
    // }

    async authButton(id) {
      let shareList = await this.$api.getUnitShareList(id)
      let {info} = await this.$api.getQuestionBankInfo(id)
      this.shareList = shareList
      this.shareList.map(item => item.id = String(item.id))
      this.qbInfo = info
      this.isQuestionBankAuthDialog = true
    },

    onFinish() {
      this.isQuestionBankAuthDialog = false
      this.initQuestionBankList(NO_DEL)
    },

    async onPreviewFinish() {
      const loading = this.$loading({
        lock: true,
        text: '正在导入，请勿离开当前页面',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        file: this.importDrawerForm.file_id,
        extra_params: {
          'qb_id': this.curQb.id,
          'in_question_type': true,
          'in_chapter': false,
          'in_real': false
        }
      };

      let info = await this.$api.storeImportQuestion(data).catch(()=>{
        loading.close()
      });

      loading.close()
      if (info === "ok") {
        this.$message.success("上传成功");
        window.location.reload()
        // this.$router.push('/import_file_list');
      } else {
        this.$message.error("上传失败");
      }

      // this.initQuestionBankList(DEL)
    }
  },
}
</script>

<style lang="stylus" scoped>

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

/*.el-select > .el-input*/
/*width 373.33px*/

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

.question-type
  width 100%
  display flex
  align-items center

  input
    margin-left 8px

  .change-type-btn
    font-size 16px
    cursor pointer

  .type-confirm-btn
    color #0099ff

  .type-cancel-btn
    color #999
    margin 0 8px

  i
    font-size 16px
    margin-left 8px
    color: #0099ff
    cursor pointer
</style>
